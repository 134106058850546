import { createWebHistory, createRouter } from "vue-router";
import Home from "./components/Home.vue";
import Login from "./components/Login.vue";
//import Register from "./components/Register.vue";
// lazy-loaded
const Card = () => import("./components/Card.vue");
const Edit = () => import("./components/Edit.vue");
const Add = () => import("./components/Add.vue");
const Settings = () => import("./components/Settings.vue");
//const BoardAdmin = () => import("./components/BoardAdmin.vue")
//const BoardModerator = () => import("./components/BoardModerator.vue")
//const BoardUser = () => import("./components/BoardUser.vue")

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/home",
    component: Home,
  },
   {
    path: "/login",
    component: Login,
  },
  {
    path: "/card",
    name:"card",
    component: Card,
  },
  {
    path: "/edit",
    name:"edit",
    component: Edit,
  },
  {
    path: "/add",
    name:"add",
    component: Add,
  },
  {
    path: "/settings",
    name:"settings",
    component: Settings,
  }
 /* {
    path: "/register",
    component: Register,
  },
  {
    path: "/profile",
    name: "profile",
    // lazy-loaded
    component: Profile,
  },
  {
    path: "/admin",
    name: "admin",
    // lazy-loaded
    component: BoardAdmin,
  },
  {
    path: "/mod",
    name: "moderator",
    // lazy-loaded
    component: BoardModerator,
  },
  {
    path: "/user",
    name: "user",
    // lazy-loaded
    component: BoardUser,
  }, */
];

const router = createRouter({
  history: createWebHistory('/employees'),
  routes,
});

export default router;