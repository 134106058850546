<template>
<header class="p-3 mb-3 border-bottom text-center">
  <img id="logo-dem" class="mb-3 img-fluid" :src="require('@/assets/img/logo-dem.png')" alt="logo">
    <div class="container">
      <nav class="navbar navbar-expand-md navbar-dark bg-primary ">
        <a class="navbar-brand" href="./home">Home</a>
        <button class="navbar-toggler order-last order-md-0" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation" @click="toggleNavbar()">
          <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse order-last order-md-0" :class="{ 'show': show }"  id="navbarTogglerDemo02">
          <ul class="navbar-nav mr-auto mt-2 mt-lg-0">
            <li class="nav-item">
              <a v-show="localLanguage == 'en'" class="nav-link" href="https://economiaemanagement.dip.unipv.it/en" target="_blank">{{ $t('dipartimento') }}<span class="sr-only">(current)</span></a>
              <a v-show="localLanguage == 'it'" class="nav-link" href="https://economiaemanagement.dip.unipv.it/it" target="_blank">{{ $t('dipartimento') }}<span class="sr-only">(current)</span></a>
              <a v-show="localLanguage == null" class="nav-link" href="https://economiaemanagement.dip.unipv.it/it" target="_blank">{{ $t('dipartimento') }}<span class="sr-only">(current)</span></a>
            </li>
          </ul>

          <div class="ml-auto mr-2 d-block d-sm-none">
            <ul class="navbar-nav mr-auto mt-2 mt-lg-0">
              <li class="nav-item">
                <a class="nav-link" @click.prevent="changeLocale('it')">It</a>
                <a class="nav-link" @click.prevent="changeLocale('en')">En</a>
                <a class="nav-link" v-if="action" @click.prevent="goToSettings()">{{ $t('impostazioni') }}</a>
                <a class="nav-link" v-show="!action" @click.prevent="logIn">Sign in</a>
                <a class="nav-link" v-show="action" @click.prevent="logOut">Sign out</a>
              </li>
            </ul>
          </div>
        </div>
        
        <div class="flags d-none d-sm-block">
          <a v-show="localLanguage == 'en'" @click.prevent="changeLocale('it')"><img id="flag-ita" class="mb-3 img-fluid" :src="require('@/assets/img/sl-it.png')" alt="lingua Italiana"></a>
          <a v-show="localLanguage == 'it'" @click.prevent="changeLocale('en')"><img id="flag-en" class="mb-3 img-fluid" :src="require('@/assets/img/sl-eng.png')" alt="lingua Inglese"></a>
          <a v-show="localLanguage == null" @click.prevent="changeLocale('it')"><img id="flag-ita" class="mb-3 img-fluid" :src="require('@/assets/img/sl-it.png')" alt="lingua Italiana"></a>
        </div>

        <div class="dropdown ml-auto mr-2 d-none d-sm-block">

          <a href="#" class="d-block text-white text-decoration-none dropdown-toggle" id="dropdownUser1" data-bs-toggle="dropdown" aria-expanded="false">
            <font-awesome-icon icon="user"/>
          </a>
          <ul class="dropdown-menu text-small" aria-labelledby="dropdownUser1">

            <li v-if="action"><a class="dropdown-item" @click.prevent="goToSettings()">{{ $t('impostazioni') }}</a></li>
            <li><hr class="dropdown-divider"></li>
            <li v-show="!action"><a class="dropdown-item"  @click.prevent="logIn">Sign in</a></li>
            <li v-show="action"><a class="dropdown-item"  @click.prevent="logOut">Sign out</a></li>
          </ul>
        </div>
      </nav>
    </div>
  </header>
</template>

<script>

import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { ref,computed } from "vue";
import {i18n} from '../plugins/i18n';



export default ({
    setup() {
        const action = ref(false);
        const store = useStore();
        const router = useRouter();
        const show = ref(false);

        const loggedIn = computed(() => {return store.state.auth.status.loggedIn;});


        if(loggedIn.value){
            action.value = true;
        }

        var localLanguage = ref( localStorage.getItem("localLanguage"));

        if(localLanguage.value){
          i18n.global.locale = localLanguage.value;
        } 

        const logOut = () =>{
            store.dispatch('auth/logout');
            router.push('/login');    
        };

        const logIn = () =>{
            router.push('/login');
        }

        const goToSettings = () =>{
            router.push('/settings');
        }

        const changeLocale = (locale) =>{
            i18n.global.locale = locale;
            localStorage.setItem("localLanguage",locale);
            localLanguage.value = locale;
        }

        const toggleNavbar = () => {
            show.value = !show.value;
        }

        return  {
            logOut,
            logIn,
            changeLocale,
            toggleNavbar,
            goToSettings,
            loggedIn,
            action,
            show,
            localLanguage
        }
        
    },
})
</script>


<style scoped>
.navbar-brand{
  margin-left: 15px;
}
.dropdown{
  margin-right: 15px;
}
.flags{
    height: 25px;
    margin-right: 10px;
}
</style>