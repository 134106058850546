import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from './plugins/font-awesome'
import {i18n} from './plugins/i18n';


const myApp = createApp(App)
  
myApp.use(router).use(store).use(i18n).component("font-awesome-icon", FontAwesomeIcon).mount("#app");


