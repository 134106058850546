<template>
  <div class="hello">
    <button v-if="edit" class="btn btn-success btn-circle btn-add-contact btn-add" @click="addEmployee">AGGIUNGI DOCENTE</button>
    <div id="resize_wrapper">
      <table id="employeesTable" class="table" style="width:100%" >
          <thead class="thead-light">
              <tr>
                  <th scope="col">{{ $t('nome') }}</th>
                  <th scope="col">{{ $t('cognome') }}</th>
                  <th scope="col">{{ $t('ruolo') }}</th>
                  <th scope="col">{{ $t('email') }}</th>
                  <th scope="col">{{ $t('modifica') }}</th>
              </tr>
          </thead>
          <tbody>
              <tr v-for="employee in employees" :key="employee.id">
                  <td scope="row">{{ employee.nome }}</td>
                  <td>{{ employee.cognome }}</td>
                  <td>{{ employee.ruolo }}</td>
                  <td>{{ employee.email }}</td>
                  <td class="edit-icons"><font-awesome-icon @click="openCard(employee.id)" icon="eye" /><span v-if="edit" ><font-awesome-icon icon="edit" @click="editCard(employee.id)" /><font-awesome-icon icon="trash" @click="deleteCard(employee.id)"/></span></td>
              </tr>
          </tbody>
          <tfoot>
              <tr>
                  <th>{{ $t('nome') }}</th>
                  <th>{{ $t('cognome') }}</th>
                  <th>{{ $t('ruolo') }}</th>
                  <th>{{ $t('email') }}</th>
                  <th>{{ $t('modifica') }}</th>
              </tr>
          </tfoot>
      </table>
    </div>
  </div>
</template>

<script>
import { ref,onMounted, computed,nextTick,watch } from 'vue';
import { useStore } from 'vuex';
const $ = require('jquery');
import "../assets/plugins/DataTables/datatables.min.js";
import eServices from "../services/employees.service";
import {i18n} from '../plugins/i18n';
import { useRouter,useRoute } from 'vue-router';


export default {
  name: 'Home',
  setup (){
    const store = useStore()
    const employees = ref([]);
    const edit = ref(false);
    const loggedIn = computed(() => {return store.state.auth.status.loggedIn;});
    const router = useRouter();
    const route = useRoute();
    const filter = computed(() => {return route.query.filter;})

    let table ;

    if(loggedIn.value){
      edit.value = true;
    }

    window.localStorage.removeItem('employeeID');


    watch(() => i18n.global.locale, (first) => {
      if (first == "it"){
        if (typeof table != 'undefined' && table != null)
        {
            table.destroy(); 
            table = null;
            table = $('#employeesTable').DataTable( {
                        language: {
                            url: '//cdn.datatables.net/plug-ins/1.10.24/i18n/Italian.json'
                        }
                      });
        }
      }else{
        if (typeof table != 'undefined' && table != null)
          {
              table.destroy(); 
              table = null;
              table = $('#employeesTable').DataTable();
          }
      }
    });

    const LoadEmployees = () =>{
        eServices.getEmployeesList().then(
            (response) => {
                employees.value = response.data;
                console.log("Dati caricati!!")
                
            },
            (error) => {
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            }
        );
    }

    const openCard = (Id) => {
      router.push({ name: "card",params: { id:Id} });
    }

    const editCard = (Id) => {
      router.push({ name: "edit",params: { id:Id} });
    }

    const addEmployee = () => {
      router.push({ name: "add"});
    }

    const deleteCard = (Id) =>{

      eServices.deleteEmployee(Id).then(
          () => {
              employees.value = [];
              clearTable();
              LoadEmployees();
              loadTable()
          },
          (error) => {
              (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
              error.message ||
              error.toString();
          }
      );

    }

    const clearTable = () =>{
        if (Object.prototype.hasOwnProperty.call(table, "destroy")){
            table.destroy();
            table = {};
        }
    }

    const loadTable = async () => { 
      console.log("Dati presenti: "+ employees.value.length)
        if (employees.value.length > 0 ){
          console.log("Caricamento tabella!")
          if (localStorage.getItem("localLanguage") == "en"){
              table = $('#employeesTable').DataTable({
                responsive:"true",
                scrollX:true
              });
              if(filter.value){
                table.search( filter.value ).draw();
              }
          }else{
              table = $('#employeesTable').DataTable( {
                responsive:"true",
                scrollX:true,
                order: [ 1, 'asc' ],
                language: {
                    url: '//cdn.datatables.net/plug-ins/1.10.24/i18n/Italian.json'
                }
              });
              if(filter.value){
                table.search( filter.value ).draw();
              }
          }
        }else{
            setTimeout(() => { loadTable(); }, 1000)
        }
    }


    nextTick(() => {


        loadTable();
        

    })

    onMounted(LoadEmployees)

     return{
         employees,
         loggedIn,
         edit,
         table,
         openCard,
         editCard,
         addEmployee,
         deleteCard
     }
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

@import "../assets/plugins/DataTables/datatables.min.css";

.edit-icons svg{
  margin-right:20px;
}

h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

td svg { cursor: pointer; }

.btn-add{
  margin-bottom: 15px;
  margin-left: 15px;
}
</style>
