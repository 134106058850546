import { createI18n } from "vue-i18n";

const messages = {
    'it': {
        nome: 'Nome',
        cognome: 'Cognome',
        ruolo: 'Ruolo',
        email: 'Email',
        modifica: 'Modifica',
        presentazione: 'Presentazione',
        corsi:'Corsi',
        contatti:'Contatti',
        anno:'Anno',
        dipartimento:'Dipartimento',
        impostazioni:'Impostazioni'
    },
    'en': {
        nome: 'Name',
        cognome: 'Surname',
        ruolo: 'Role',
        email: 'Email',
        modifica: 'Edit',
        presentazione: 'Presentation',
        corsi:'Courses',
        contatti:'Contact',
        anno:'Year',
        dipartimento:'Department',
        impostazioni:'Settings'
    }
};

const i18n = createI18n({
    locale: 'it', // set locale
    fallbackLocale: 'en',
    globalInjection: true, // set fallback locale
    messages, // set locale messages
});

export {i18n};