import axios from 'axios';
import authHeader from './auth-header';

const API_URL = process.env.VUE_APP_API_URL + '/api/employees';

class EmployeesService {
  getEmployeesList() {
    return axios.get(API_URL );
  }

  getEmployee(id) {
    return axios.get(API_URL + "/"+ id);
  }

  updateEmployee(id,employee) {
    return axios.patch(API_URL + "/"+ id,employee, { headers: authHeader() });
  }

  fileUpload(fileUp){
    return axios.post(API_URL + '/fileupload',fileUp, { headers: authHeader() });
  }
  addEmployee(employee){
    return axios.post(API_URL,employee, { headers: authHeader() });
  }
  deleteEmployee(id){
    return axios.delete(API_URL +"/"+ id, { headers: authHeader() });
  }
  globalUpdateYear(year){
    return axios.post(API_URL+"/globalupdateyear/"+year, { headers: authHeader() });
  }

 /* getModeratorBoard() {
    return axios.get(API_URL + 'mod', { headers: authHeader() });
  }

  getAdminBoard() {
    return axios.get(API_URL + 'admin', { headers: authHeader() });
  } */
}

export default new EmployeesService();