<template>
  
  <!-- <Home msg="Welcome to Your Vue.js App"/> -->
  <div id="app">
    <navbar/>
    <div class="container"> 
      <router-view />
    </div>
  </div>
  
</template>

<script>
//import Home from './components/Home.vue'
import Navbar from "./components/Navbar.vue";
import {provide} from 'vue'

export default {
  name: 'App',
  components: {
    Navbar
  },
  setup(){
    provide('api_url',process.env.VUE_APP_API_URL)
  } 
}
</script>

<style>
/* #app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
} */
</style>
